// Settings

$sidebar-width:           200px;
$panel-radius:            10px;

$scrollbar-track:         #c2c2c2;
$scrollbar-thumb:         #759fc3;
$scrollbar-width:         6px;
$scrollbar-spacing:       6px;

// Base Colors

$base-white:              #fff;
$base-grey:               #E6E6E6;
$base-light-grey:         #f1f1f1;
$base-dark-grey:          #40576d;

$base-text:               #262626;
$base-text-alt:           #fff;
$base-text-grey:          #929292;
$base-link-text:          #136396;

$base-dark:               #00213e;
$base:                    #002f57;
$base-light:              #003664;

$base-button:             #1e80c1;
$base-button-text:        #fff;
$base-button-active:      #fff;
$base-button-active-text: #1e80c1;


// Dashboard Colors

$dashboard-text:            #166496;
$dashboard-icon:            #7a4faa;
$dashboard-background:      #e3f3f9;
$dashboard-button:          #1e81c1;
$dashboard-button-text:     $base-text-alt;


// Dossier Colors

$dossier-text:            #fa6b50;
$dossier-icon:            #fa6b50;
$dossier-background:      #fee9e5;
$dossier-button:          #fa6b50;
$dossier-button-text:     $base-text-alt;


// Progress Colors

$progress-text:           #eb5462;
$progress-icon:           #eb5462;
$progress-background:     #fce6e8;
$progress-button:         #eb5462;
$progress-button-text:    $base-text-alt;


// Exercises Colors

$exercises-text:          #166496;
$exercises-icon:          #43b0d7;
$exercises-background:    #ddecf6;
$exercises-button:        #1e81c1;
$exercises-button-text:   $base-text-alt;


// History Colors

$history-text:            #22997d;
$history-icon:            #46ceae;
$history-background:      #e3f8f3;
$history-button:          #46ceae;
$history-button-text:     $base-text-alt;


// Agenda Colors

$agenda-text:             #206096;
$agenda-icon:             #206096;
$agenda-background:       #dee7ef;
$agenda-button:           #206096;
$agenda-button-text:      $base-text-alt;


// Notes Colors

$notes-text:              #e58f29;
$notes-icon:              #e58f29;
$notes-background:        #fbf1e5;
$notes-button:            #e58f29;
$notes-button-text:       $base-text-alt;


// Bootstrap changes

$input-border:                   $base-grey;
$input-border-radius:            4px;
$input-color-placeholder:        $base-text;